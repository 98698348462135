import Background from "../../components/Background";
import "./index.css";
import Footer from "../../components/Footer";
import {useParams} from "react-router";
import PATTERN from "../../assets/patterns/side-1.svg";
import PATTERN_MOBILE from "../../assets/patterns/side-1-mobile.svg";
import {useWindowSize} from "../../hooks/useWindowSize";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {RootState} from "../../store/configureStore";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchingHome} from "../../actions/apiActions";
import cookies from "js-cookie";
import {storageBase} from "../../utils/storage";
import {Spin} from "antd";

const NewsDetails = () => {
    const {news} = useSelector((state: RootState) => state.homeData.data);
    const dispatch = useDispatch();

    const { slug } = useParams();
    const windowSize = useWindowSize();
    const { t } = useTranslation();
    useEffect(() => {
        //@ts-ignore
        dispatch(fetchingHome("home"));
    }, [dispatch]);

    const [newsItem, setNewsItem] = useState<any>();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const newsItem = news?.find((item: any) => {
            return item.id == slug
        });
        if(newsItem){
            setLoading(false);
        }
        setNewsItem(newsItem);
    }, [news]);
    const lang = cookies.get("i18next") || "ru";

    if (loading)
        return (
            <div className='loadingContainer'>
                <Spin size='large' />
            </div>
        );
    return (
        <>
            <Helmet>
                <title>
                    {newsItem && newsItem[`title_${lang}`]}
                </title>
            </Helmet>
            <Background
                style={{flexDirection: "column"}}
                pattern1={windowSize.width < 975 ? PATTERN_MOBILE : PATTERN}>
                <div className='ecosystemDetails'>
                    <div className='ecosystemDetails_'>
                        <div className='ecosystemDetails-title'>
                            <h1>{newsItem && newsItem[`title_${lang}`]}</h1>
                        </div>
                        <div className='ecosystemDetails-header'>
                            <div className='ecosystemDetails-content'>
                                <img src={`${storageBase}/${newsItem?.image}`} alt={newsItem && newsItem[`title_${lang}`]} style={{width: '100%'}}/>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: newsItem && newsItem[`description_${lang}`],
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Background>
            <Footer/>
        </>
    );
};

export default NewsDetails;
