import React, {useEffect, useRef, useState} from "react";
import ARROW from "../../assets/arrow.svg";
import "./index.css";
import cookies from "js-cookie";
import Header from "../Header";
import {storageBase} from "../../utils/storage";
import {useSearchParams} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper/modules';

interface EcosystemSliderProps {
    data: any;
}

const EcosystemSlider: React.FC<EcosystemSliderProps> = ({data}) => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    const [active, setActive] = useState(0);
    const lang = cookies.get("i18next");

    const prevSlide = () => {
        setActive((active - 1 + data.length) % data.length);
    };

    const nextSlide = () => {
        setActive((active + 1) % data.length);
    };
    const [searchParams, setSearchParams] = useSearchParams({})
    // @ts-ignore
    const selectedId = searchParams.get('tab')
    useEffect(() => {
        // @ts-ignore
        const element = document.getElementById(selectedId);
        if (element) {
            const offset = -100; // Задайте желаемый отрицательный отступ
            element.scrollIntoView({block: 'start', inline: 'nearest'});
            window.scrollBy(0, offset);
        }
    }, [selectedId]);
    return (
        <div className='card_slider ecosystem_slider'>
            {data.map((ecosystem: any, index: number) => (
                <div
                    id={ecosystem.id}
                    key={index}
                    className='card-container'
                    style={
                        {
                            "--offset": (active - index) / 3,
                            "--abs-offset": Math.abs(active - index) / 3,
                            "--direction": Math.sign(active - index),
                            "--opacity": Math.abs(active - index) <= 1 ? 1 : 0,
                            "--active": index === active ? 1 : 0,
                        } as React.CSSProperties
                    }>
                    <div className='ecosystem' key={ecosystem.id}>
                        <div className='ecosystemInner'>
                            <Header
                                title={ecosystem.title}
                                description={`${ecosystem.description.slice(0, 150)}...`}
                                btns={ecosystem.btn}
                                icon={ecosystem.headerIcon}
                                btnStyles={ecosystem.btnStyle}
                                style={{padding: 0}}
                                mainImg={ecosystem.mainImg}
                                isEcosystem={true}
                                className='homePageHeader'
                            />
                            <div className='img'>
                                <img
                                    src={ecosystem.mainImg}
                                    alt='Icon'
                                    decoding='async'
                                    loading='lazy'
                                />
                            </div>
                        </div>
                        {ecosystem.partners && (
                            <div className='projectDetails_slider_1'>
                                <button className='leftBtn' ref={navigationPrevRef}><img src={ARROW} alt='Arrow'
                                                                                         decoding='async'
                                                                                         loading='lazy'/>
                                </button>
                                <button className='rightBtn' ref={navigationNextRef}><img src={ARROW}
                                                                                          alt='Arrow'
                                                                                          decoding='async'
                                                                                          loading='lazy'/>
                                </button>
                                <Swiper
                                    spaceBetween={15}
                                    slidesPerView={'auto'}
                                    cssMode={true}
                                    breakpoints={{
                                        600: {
                                            slidesPerView: 3,
                                            spaceBetween: 20,
                                            cssMode: false
                                        },
                                        995: {
                                            slidesPerView: 4,
                                            spaceBetween: 30,
                                            cssMode: false
                                        },
                                    }}
                                    navigation={{
                                        prevEl: navigationPrevRef.current,
                                        nextEl: navigationNextRef.current,
                                    }}
                                    onBeforeInit={(swiper: any) => {
                                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                                        swiper.params.navigation.nextEl = navigationNextRef.current;
                                    }}
                                    modules={[Navigation]}
                                >
                                    {ecosystem.partners.map((partner: any) => {
                                        return (
                                            <SwiperSlide key={partner.id}>
                                                <a
                                                    href={partner.url}
                                                    className='ecosystemDetails_partners_item'>
                                                    <img
                                                        alt='sliderImage'
                                                        src={`${storageBase}/${partner.image}`}
                                                        decoding='async'
                                                        loading='lazy'
                                                    />
                                                </a>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                        )}
                    </div>
                </div>
            ))}
            <button className='nav left' onClick={prevSlide}>
                <img src={ARROW} alt='Arrow' decoding='async' loading='lazy'/>
            </button>
            <button className='nav right' onClick={nextSlide}>
                <img src={ARROW} alt='Arrow' decoding='async' loading='lazy'/>
            </button>
        </div>
    );
};

export default EcosystemSlider;
