import {Fragment, useEffect, useRef, useState} from "react";
import LOGO from "../../assets/logo.svg";
import BIG_PATTERN from "../../assets/bigPatternNavbar.svg";
import SIDE_PATTERN from "../../assets/patterns/side-1-mobile.svg";
import {useWindowSize} from "../../hooks/useWindowSize";
import Button from "../Button";
import "./index.css";
import {Link, NavLink, useNavigate, useSearchParams} from "react-router-dom";
import {scrollToTop} from "../../globalFunctions/scrollToTop";
import {createBrowserHistory} from "history";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/configureStore";
import NOTIFICATION from "../../assets/notification.svg";
import {storageBase} from "../../utils/storage";
import NO_IMAGE from "../../assets/no-image-user.png";
import cookies from "js-cookie";
import i18next from "i18next";
import {usePostRequest} from "../../actions/apiActions";
import {logout} from "../../actions/authActions";
import {congratsModal} from "../../actions/congratsAction";
import ARROW from "../../assets/info/dropdown-arrow2.svg";
import EcoSystemDetails from "../../pages/EcosystemDetails";
import {useLocation} from "react-router";
import {color} from "framer-motion";
import DropDown from "../Dropdown";
import {Dropdown} from "antd";
import {selectIdAction} from "../../actions/newAction";

export const history = createBrowserHistory(); // Create a history instance
export const hasPreviousHistory = () => {
    return !!(window.history && window.history.length > 1);
};

export const menu = [
    {
        id: 1,
        name: "home",
        link: "/",
        open: false
    },
    {
        id: 2,
        name: "projects",
        link: "/projects",
        open: false
    },
    // {
    //   id: 3,
    //   name: "media",
    //   link: "/media",
    // },
    // {
    //   id: 4,
    //   name: "calendar",
    //   link: "/calendar",
    // },
    {
        id: 5,
        name: "about-us",
        link: "/about-us",
        open: false
    },
    // {
    //     id: 6,
    //     name: "contact",
    //     link: "/contact",
    //     open: false
    // },
    {
        id: 7,
        name: "news",
        link: "/#news",
        open: false
    },
    {
        id: 8,
        link: "/ecosystem/sages",
        name: "sages",
        open: false
    },
];

interface NavbarProps {
    setOpenModal: (arg: boolean) => void;
    signIn: boolean;
}

const Navbar: React.FC<NavbarProps> = ({setOpenModal, signIn}) => {
    const dropdownMenuRef = useRef(null);
    const [openMenuList, setOpenMenuList] = useState(false)
    const windowSize = useWindowSize();
    const [openMenu, setOpenMenu] = useState(false);
    useEffect(() => {
        //@ts-ignore
        const handleClickOutside = (event) => {
            //@ts-ignore
            if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target)) {
                setOpenMenuList(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        document.body.classList.toggle("no-scroll", openMenu);
        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, [openMenu]);
    const langs = [
        {
            id: 1,
            code: "en",
            longName: "ENGLISH",
        },
        {
            id: 2,
            code: "ru",
            longName: "Русский",
        },
        {
            id: 3,
            code: "am",
            longName: "Հայերեն",
        },
    ];
    const handleLanguageChange = (language: string) => {
        i18next.changeLanguage(language).then(() => {
            setOpenLangs(false);
            const currentPath = window.location.pathname;
            const languagePrefix = currentPath.split("/")[1];
            let newPath;
            if (languagePrefix && languagePrefix.length === 2) {
                newPath = currentPath.replace(`/${languagePrefix}`, `/${language}`);
            } else {
                newPath = `/${language}${currentPath}`;
            }
            window.location.href = newPath;
        });
    };

    const [openLangs, setOpenLangs] = useState(false);
    const lang = cookies.get("i18next");
    const copyLangs = langs.filter(item => item.code !== lang);
    const differentLang = langs.find(
        item1 => !copyLangs.some(item2 => item1.id === item2.id)
    );
    const {t} = useTranslation();

    //@ts-ignore
    const user = JSON.parse(localStorage.getItem("user"));
    const {isAuthenticated} = useSelector((state: RootState) => state.auth);
    const {postRequest, postLoading, response, error} = usePostRequest();
    const dispatch = useDispatch();
    const [hasNavigated, setHasNavigated] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (response && response?.data.response_code === 12 && !hasNavigated) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            dispatch(logout());
            setHasNavigated(true);
            !hasNavigated && navigate(`/${lang}/`);
            // dispatch(congratsModal(true, t("validation-errors.data-invalid")));
        } else if (error) {
            if (
                error.response?.status == 401 &&
                error.response?.data.response_code === 13
            ) {
                dispatch(congratsModal(true, t("validation-errors.token-not-found")));
            }
        }
    }, [response, error]);
    const [searchParams , setSearchParams] = useSearchParams({})

    const selectedId = searchParams.get('tab')

    const dropMenu = [
        {
            id: 1,
            text: t('Sages')
        },
        {
            id: 2,
            text: t('WiseMen')
        },
        {
            id: 3,
            text: t("Club 301")
        },
        {
            id: 4,
            text: t('Ambassadors')
        },
        {
            id: 5,
            text: t("Experts")
        }
    ]

    // useEffect(() => {
    //   if (error) {
    //     if (
    //       error.response?.data?.response_code === 31 ||
    //       error.response?.data?.response_code === 32
    //     ) {
    //       localStorage.removeItem("user");
    //       localStorage.removeItem("token");
    //       dispatch(logout());
    //       navigate(`/${lang}/`);
    //       dispatch(congratsModal(true, t("congrats.login-again")));
    //     }
    //   }
    // }, [error]);
    return (
        <div
            className='navbarContainer'
            style={{zIndex: signIn || windowSize.width > 800 ? "99999" : "999999"}}>
            {windowSize.width < 975 && (
                <div className='mobileMenu'>
                    <div
                        className={`${openMenu && "openedHamburger"} hamburger`}
                        onClick={() => {
                            setOpenMenu(!openMenu)
                            setOpenMenuList(false)
                        }}>
                        <div className='line'></div>
                        <div className='line'></div>
                        <div className='line'></div>
                    </div>
                    {/* <p>Menu</p> */}
                    {openMenu && (
                        <div className='openedMobileMenu'>
                            <div className='bigPatternNav'>
                                <img
                                    src={BIG_PATTERN}
                                    alt='Pattern'
                                    decoding='async'
                                    loading='lazy'
                                />
                            </div>
                            <div className='sidePattern1'>
                                <img
                                    src={SIDE_PATTERN}
                                    alt='Pattern'
                                    decoding='async'
                                    loading='lazy'
                                />
                            </div>
                            <div className='sidePattern2'>
                                <img
                                    src={SIDE_PATTERN}
                                    alt='Pattern'
                                    decoding='async'
                                    loading='lazy'
                                />
                            </div>
                            <div className='menu'>
                                <div className='link'>
                                    {isAuthenticated && (
                                        <div className='navbar_user_wrapper'>
                                            <NavLink
                                                to={`/${lang}/personal/personal-info`}
                                                className='navbar_user'
                                                onClick={() => setOpenMenu(false)}>
                                                <img
                                                    src={
                                                        user?.image
                                                            ? `${storageBase}/upload/user_image/${user?.image}`
                                                            : NO_IMAGE
                                                    }
                                                    alt='Person'
                                                    decoding='async'
                                                    loading='lazy'
                                                />
                                                <p style={{fontSize: "18px", margin: 0}}>
                                                    {user?.name} {user?.last_name}
                                                </p>
                                            </NavLink>
                                        </div>
                                    )}
                                    <>

                                        {menu.map((link, i) => (
                                            <NavLink
                                                className={window.location.href.includes("#news") && link.name === "news"
                                                    ? "active-hash" : (location.pathname === `/${lang}${link.link}` || (location.pathname === link.link ||( window.location.search && link.link === '/'))) && !window.location.href.includes("#news")
                                                        ? "activeee" : ""}
                                                onClick={() => {
                                                    setOpenMenu(false);
                                                    if(link.name !== "news") {
                                                        scrollToTop();
                                                    } else {
                                                        document.getElementById('news')?.scrollIntoView({ behavior: 'smooth' });
                                                    }
                                                }}
                                                key={i}
                                                to={`/${lang}${link.link}`}
                                                style={{animationDelay: `${i * 0.1}s`}}>
                                                {t(`navbar.${link.name}`)}
                                            </NavLink>
                                        ))}
                                        <a style={{padding: 0 , zIndex:999999999}} className='dropdown-menu-nav'>
                                            <div
                                                style={{padding: 0}}

                                                onClick={() => setOpenMenuList(!openMenuList)}
                                                className='custom-dropDown-icon'
                                            >
                                                <span style={{
                                                    fontSize:'clamp(1.125rem, 0rem + 5.625vw, 2.109375rem);'
                                                }}>{t('Ecosystem 301')}</span> <img style={{
                                                transform: openMenuList ? 'rotate(180deg)' : '',
                                                marginLeft:15
                                            }} src={ARROW} alt=""/>
                                            </div>
                                            {openMenuList && (
                                                <div className='menu-items-nav'>
                                                    {dropMenu.map((e) => (
                                                        <p
                                                            style={{
                                                                fontSize:'clamp(1.125rem, 0rem + 5.625vw, 2.109375rem);',
                                                                paddingLeft: (selectedId && +selectedId === +e.id) ?  15 : 0,
                                                                paddingTop: (selectedId && +selectedId === +e.id) ?  10 : 0,
                                                                paddingBottom: (selectedId && +selectedId === +e.id) ?  10 : 0,
                                                                background:(selectedId && +selectedId === +e.id) ? '#dd264e' :0,
                                                                color:(selectedId && +selectedId === +e.id) ? 'white' : '',
                                                                borderRadius:(selectedId && +selectedId === +e.id) ? 20 : 0
                                                            }}
                                                            onClick={() => {
                                                                setOpenMenu(false);

                                                            navigate(`/${lang}?tab=${e.id}`)
                                                            // @ts-ignore
                                                            // dispatch(selectIdAction(e.id))
                                                            setOpenMenuList(false)
                                                        }} className='dropdown-p' key={e.id}>{e.text}</p>
                                                    ))}
                                                </div>
                                            )}
                                        </a>
                                    </>
                                </div>
                                <div className='link logout'>
                                    <NavLink
                                        to={`/${lang}/login`}
                                        onClick={() => {
                                            const token = localStorage.getItem("token");
                                            postRequest(
                                                "logout",
                                                {},
                                                {
                                                    Authorization: `Bearer ${token}`,
                                                }
                                            );
                                        }}>
                                        {!isAuthenticated
                                            ? t("navbar.sign-in")
                                            : t("navbar.logout")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {openMenu ? (
                <div className='logo'>
                    <span>{t('menu')}</span>
                    <Button
                        text={t(`btns.donate`)}
                        link={true}
                        to={`/${lang}/donation`}
                        className='signIn-btn'
                        onClick={() => {
                            setOpenMenu(false);
                        }}
                        style={{
                            padding: "9px 23px",
                            background: "var(--main-color)",
                            color: "#fff",
                            display: "block",
                            fontSize: "12px",
                        }}
                    />
                    {isAuthenticated && (
                        <button className='navbar_notif'>
                            <img
                                src={NOTIFICATION}
                                alt='Notification'
                                decoding='async'
                                loading='lazy'
                            />
                            <span className='notification_number'>0</span>
                        </button>
                    )}
                </div>
            ) : (
                <NavLink to={`/${lang}/`} className='logo' onClick={scrollToTop}>
                    <img src={LOGO} alt='Logo' decoding='async' loading='lazy'/>
                </NavLink>
            )}
            <div className='menu'>
                <div className='link'>
                    <>
                        {menu.map((link, i) => (
                            <a
                                key={i}
                                href={`/${lang}${link.link}`}
                                className={window.location.href.includes("#news") && link.name === "news"
                                    ? "active-hash" : (location.pathname === `/${lang}${link.link}` || (location.pathname === link.link ||( window.location.search && link.link === '/'))) && !window.location.href.includes("#news")
                                        ? "activeee" : ""}
                            >
                                {t(`navbar.${link.name}`)}
                            </a>

                        ))}
                        <div className='dropdown-menu-nav' ref={dropdownMenuRef}>
                            <div

                                onClick={() => setOpenMenuList(!openMenuList)}
                                className='custom-dropDown-icon'
                            >
                                <span>{t('Ecosystem 301')}</span> <img style={{
                                transform: openMenuList ? 'rotate(180deg)' : '',
                                marginLeft:15
                            }} src={ARROW} alt=""/>
                            </div>
                            {openMenuList && (
                                <div className='menu-items-nav'>
                                    {dropMenu.map((e) => (
                                        <p  style={{
                                            paddingLeft: (selectedId && +selectedId === +e.id) ?  15 : 0,
                                            paddingRight: (selectedId && +selectedId === +e.id) ?  15 : 0,
                                            paddingTop: (selectedId && +selectedId === +e.id) ?  10 : 0,
                                            paddingBottom: (selectedId && +selectedId === +e.id) ?  10 : 0,
                                            background:(selectedId && +selectedId === +e.id) ? '#dd264e' :0,
                                            color:(selectedId && +selectedId === +e.id) ? 'white' : '',
                                            borderRadius:(selectedId && +selectedId === +e.id) ? 20 : 0
                                        }}
                                            onClick={() => {
                                            navigate(`/${lang}?tab=${e.id}`)
                                            // @ts-ignore
                                            // dispatch(selectIdAction(e.id))
                                            setOpenMenuList(false)
                                        }} className='dropdown-p' key={e.id}>{e.text}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    </>
                </div>
                <div className='langsWrapper'>
                    <Button
                        text={
                            windowSize.width < 800 ? differentLang?.code : differentLang?.code
                        }
                        link={false}
                        to={""}
                        className='activeLang lang'
                        onClick={() => setOpenLangs(!openLangs)}
                    />
                    <div className='notActiveLangs_wrapper'>
                        {copyLangs.map((l, i) => (
                            <a
                                href={`/${l.code}${window.location.pathname}`}
                                key={i}
                                onClick={e => {
                                    e.preventDefault();
                                    handleLanguageChange(l.code);
                                }}>
                                <Button
                                    text={l.code}
                                    link={false}
                                    to={""}
                                    className={`${openLangs && "openedLang"} activeLang_${
                                        i + 1
                                    } lang`}
                                />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
            {windowSize.width > 800 ? (
                <>
                    {isAuthenticated ? (
                        <div className='navbar_user_wrapper'>
                            <button className='navbar_notif'>
                                <img
                                    src={NOTIFICATION}
                                    alt='Notification'
                                    decoding='async'
                                    loading='lazy'
                                />
                                <span className='notification_number'>0</span>
                            </button>
                            <NavLink
                                to={`/${lang}/personal/personal-info`}
                                className='navbar_user'>
                                <img
                                    src={
                                        user?.image
                                            ? `${storageBase}/upload/user_image/${user?.image}`
                                            : NO_IMAGE
                                    }
                                    alt='Person'
                                    decoding='async'
                                    loading='lazy'
                                />
                                <p>
                                    {user?.name} {user?.last_name}
                                </p>
                            </NavLink>
                        </div>
                    ) : (
                        <div className='btns' style={{margin: 0}}>
                            <Button
                                text={t(`btns.donate`)}
                                link={true}
                                to={`/${lang}/donation`}
                                className='signIn-btn'
                                // onClick={() => {
                                //   // dispatch(openDonateModal(true));
                                //   // dispatch(getModalName("donate"));
                                // }}
                                style={{
                                    padding: "9px 23px",
                                    background: "var(--main-color)",
                                    color: "#fff  ",
                                    fontSize: "13px",
                                }}
                            />
                            <Button
                                text={t(`navbar.sign-in`)}
                                link={true}
                                to={`/${lang}/login`}
                                className='signIn-btn'
                                style={{padding: "9px 23px", fontSize: "13px"}}
                            />
                        </div>
                    )}
                </>
            ) : (
                <Button
                    text={t(`navbar.sign-in`)}
                    link={false}
                    to=''
                    className='signIn-btn'
                    onClick={() => setOpenModal(true)}
                    style={{padding: "9px 23px"}}
                />
            )}
        </div>
    );
};

export default Navbar;
