import Background from "../Background";
import Header from "../Header";
import HeaderIcon from "../../assets/info/news.svg";
import SIDE_PATTERN_2 from "../../assets/patterns/side-2.svg";
import SIDE_PATTERN_2_MOBILE from "../../assets/patterns/side-2-mobile.svg";
import ARROW from "../../assets/arrow.svg";
import "./index.css";
import React, {useRef} from "react";
import {useWindowSize} from "../../hooks/useWindowSize";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RootState} from "../../store/configureStore";
import {HeaderKeyOf} from "../../utils/keyof.type";
import {storageBase} from "../../utils/storage";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper/modules";

export interface newsTypes {
    id: number;
    title_am: string;
    title_en: string;
    title_ru: string;
    description_am: string;
    description_ru: string;
    description_en: string;
    image: string;
    created_at: string;
}

interface NewsProps {
    lang: string;
}

const News: React.FC<NewsProps> = ({lang}) => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    const windowSize = useWindowSize();
    const {t} = useTranslation();
    const {news} = useSelector((state: RootState) => state.homeData.data);

    return (
        <>
            <div className='separatedPart'></div>
            <Background
                pattern1={
                    windowSize.width < 975 ? SIDE_PATTERN_2_MOBILE : SIDE_PATTERN_2
                }
                pattern2LeftStyle={{
                    width: "190px",
                    height: "190px",
                    top: "350px",
                }}
                pattern2RightStyle={{
                    display: "none",
                }}
                style={{flexDirection: "column", padding: "0"}}>
                <Header
                    title={t("news-301")}
                    description=''
                    icon={HeaderIcon}
                    style={{
                        paddingTop: "40px",
                        paddingBottom: "40px",
                        width: "63vw",
                        alignItems: "center",
                    }}
                    id='news'
                />
                <div className='projectsContainer'>
                    <button className='leftBtn' ref={navigationPrevRef}><img src={ARROW} alt='Arrow' decoding='async'
                                                                             loading='lazy'/>
                    </button>
                    <button className='rightBtn' ref={navigationNextRef}><img src={ARROW} alt='Arrow' decoding='async'
                                                                              loading='lazy'/>
                    </button>
                    <Swiper
                        spaceBetween={15}
                        slidesPerView={'auto'}
                        cssMode={true}
                        breakpoints={{
                            600: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                                cssMode: false
                            },
                            995: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                                cssMode: false
                            },
                        }}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        modules={[Navigation]}
                        onBeforeInit={(swiper: any) => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current;
                            swiper.params.navigation.nextEl = navigationNextRef.current;
                        }}
                    >
                        {news.map((item: any) => {
                            const dynamicTitle = item[`title_${lang}` as keyof HeaderKeyOf];
                            const altText =
                                typeof dynamicTitle === "string" ? dynamicTitle : "";
                            return (
                                <SwiperSlide key={item.id}>
                                    <a href={`/${lang}/news/${item.id}`} className={`news`} style={{display: 'block'}}>
                                        <div className='newsImg'>
                                            <img
                                                src={`${storageBase}/${item.image}`}
                                                alt={altText}
                                                decoding='async'
                                                loading='lazy'
                                            />
                                        </div>
                                        <div className='newsContent'>
                                            <h1>{dynamicTitle}</h1>
                                        </div>
                                    </a>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </Background>
        </>
    );
};

export default News;
