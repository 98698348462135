import Background from "../Background";
import Header from "../Header";
import SIDE_PATTERN_2 from "../../assets/patterns/side-2.svg";
import SIDE_PATTERN_2_MOBILE from "../../assets/patterns/side-2-mobile.svg";
import SMALL_PATTERN_1 from "../../assets/patterns/small-1.svg";
import ICON from "../../assets/info/4.svg";
import "./index.scss";
import Button from "../Button";
import {useWindowSize} from "../../hooks/useWindowSize";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RootState} from "../../store/configureStore";
import {HeaderTypes, ProjectsTypes} from "../../utils/api.types";
import {HeaderKeyOf, ProjectKeyOf} from "../../utils/keyof.type";
import SingleProjectBox from "../SingleProjectBox";
import {storageBase} from "../../utils/storage";
import {NavLink} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import ARROW from "../../assets/arrow.svg";
import React, {useRef} from "react";

interface ProjectsProps {
    OurProjects: HeaderTypes[];
    lang: string;
    projects: ProjectsTypes[];
}

const Projects: React.FC<ProjectsProps> = ({OurProjects, lang}) => {
    const {t} = useTranslation();
    const windowSize = useWindowSize();
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    const {projects} = useSelector((state: RootState) => state.homeData.data);
    return (
        <>
            {projects && (
                <>
                    <div className='separatedPart'></div>
                    <Background
                        pattern1={
                            windowSize.width < 975 ? SIDE_PATTERN_2_MOBILE : SIDE_PATTERN_2
                        }
                        pattern2={SMALL_PATTERN_1}
                        shoudHaveSidePattern={false}
                        style={{padding: "60px 0"}}>
                        <div className='projectsContainer' id='projects'>
                            <Header
                                title={OurProjects[0][`title_${lang}` as keyof HeaderKeyOf]}
                                description={
                                    OurProjects[0][`description_${lang}` as keyof HeaderKeyOf]
                                }
                                icon={ICON}
                                className='differedHeaderContainer'
                                style={{width: "100%"}}
                            />
                            <button className='leftBtn' ref={navigationPrevRef}><img src={ARROW} alt='Arrow' decoding='async' loading='lazy'/>
                            </button>
                            <button className='rightBtn' ref={navigationNextRef}><img src={ARROW} alt='Arrow' decoding='async' loading='lazy'/>
                            </button>
                            <Swiper
                                spaceBetween={15}
                                slidesPerView={'auto'}
                                cssMode={true}
                                breakpoints={{
                                    600: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                        cssMode: false
                                    },
                                    995: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                        cssMode: false
                                    },
                                }}
                                navigation={{
                                    prevEl: navigationPrevRef.current,
                                    nextEl: navigationNextRef.current,
                                }}
                                modules={[Navigation]}
                                onBeforeInit={(swiper: any) => {
                                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                                    swiper.params.navigation.nextEl = navigationNextRef.current;
                                }}
                            >
                                {projects.map((project: any) => {
                                    return (
                                        <SwiperSlide key={project.project.id}>
                                            <NavLink
                                                to={`/${lang}/projects/${project.project.slug}`}>
                                                <SingleProjectBox
                                                    title={
                                                        project.project[
                                                            `project_name_${lang}` as keyof ProjectKeyOf
                                                            ]
                                                    }
                                                    flag={
                                                        project?.project?.payment_type !== "buy" &&
                                                        project?.project?.payment_type !== "book" &&
                                                        project.map_count
                                                    }
                                                    author={
                                                        project[`sector_${lang}` as keyof ProjectKeyOf]
                                                    }
                                                    projectImg={`${storageBase}/${project.project.image}`}
                                                    className='home_project'
                                                />
                                            </NavLink>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                            <div className='btns'>
                                <Button
                                    text={t("btns.whole-project")}
                                    style={{
                                        background: "#DD264E",
                                        boxShadow: "-21px 16px 38px 0px rgba(191, 9, 48, 0.21)",
                                        color: "#fff",
                                    }}
                                    link={true}
                                    to='/projects'
                                    className='homePage_btn'
                                />
                            </div>
                        </div>
                    </Background>
                </>
            )}
        </>
    );
};

export default Projects;
